<template>
  <div class="subitem">
    <div class="subitem-header">
      <div class="subitem-header-title">
        <span class="subitem-header-line"></span>
        <span>分类列表</span>
      </div>
      <div class="subitem-header-handle">
        <a-button type="primary" @click="showModal(null)">添加</a-button>
      </div>
    </div>
    <BasicTable ref="tableRef" v-bind="tableConfig"> </BasicTable>
  </div>
  <SubitemEditModal
    v-if="modalVisible"
    v-model:visible="modalVisible"
    :projectId="selectedProject"
    :spaceId="currentSpaceId"
    type="2"
    @success="editSuccess"
  ></SubitemEditModal>
</template>

<script setup>
import { defineProps, watch, ref, reactive, onMounted } from "vue";
import { Modal, message } from "ant-design-vue";
import { apiSubitem } from "@/api/configuration";
import { BasicTable } from "@/components/basic/table";
import SubitemEditModal from "./component/SubitemEditModal";

const tableRef = ref();

const props = defineProps({
  selectedProject: {
    type: String,
    default: "",
  },
});
watch(
  () => props.selectedProject,
  () => {
    initTableData();
  }
);

onMounted(() => {
  initTableData();
});

const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: false,
  isPageBottom: true,
  remote: false,
  loading: false,
  defaultExpandAllRows: true,
  isFinish: true,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 280px)",
  },
  columns: [
    {
      title: "分类名称",
      type: "text",
      key: "name",
      width: 180,
      align:"left"
    },
    {
      title: "类型",
      type: "text",
      key: "kpiSubtypeStr",
      width: 80,
      align:"left"
    },
    {
      title: "计量设备及计量算法",
      type: "text",
      key: "devicesDesc",
      width: 300,
      align:"left"
    },
  ],
  action: {
    title: "操作",
    width: 70,
    fixed: "right",
    align:"left",
    items: [
      {
        type: "icon",
        icon: "FormOutlined",
        iconTip: "修改",
        onClick: (row) => {
          showModal(row);
        },
      },
      {
        type: "icon",
        icon: "DeleteOutlined",
        iconTip: "删除",
        visibleFn: (row) => !row.children?.length,
        onClick: (row) => {
          Modal.confirm({
            title: "提示",
            content: "是否删除该条数据？",
            okType: "danger",
            onOk: () => {
              return new Promise((resolve, reject) => {
                apiSubitem
                  .delete(row.id)
                  .then(() => {
                    message.success("删除成功");
                    initTableData();
                    resolve();
                  })
                  .catch(() => {
                    reject();
                  });
              });
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        },
      },
    ],
  },
});
const initTableData = async () => {
  if (!props.selectedProject) {
    return;
  }
  tableConfig.loading = true;
  tableConfig.isFinish = false;
  try {
    const { result } = await apiSubitem.list(props.selectedProject, 2);
    tableConfig.loading = false;
    tableConfig.isFinish = true;
    tableRef.value.dataSourceRef = result;
  } catch (error) {
    tableConfig.loading = false;
    tableConfig.isFinish = true;
    console.log(error);
  }
};

const modalVisible = ref(false);
const currentSpaceId = ref(false);
const showModal = (row) => {
  currentSpaceId.value = row?.id ?? null;
  modalVisible.value = true;
};
const editSuccess = () => {
  initTableData();
};
</script>

<style lang="less" scoped>
.subitem {
  height: 100%;
  &-header {
    display: flex;
    justify-content: space-between;
    &-title {
      display: flex;
      align-items: center;
      gap: 10px;
      height: 56px;
      font-size: 16px;
    }
    &-line {
      width: 3px;
      height: 16px;
      background: #0256ff;
    }
    &-handle {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
</style>
