<template>
  <TwoColumnsLayout leftWidth="240px">
    <template #left>
      <LeftSelectTree title="项目树" v-model:selected="selected" :treeData="treeData" :loading="treeLoading" :fieldNames="{
        title: 'name',
        key: 'selectId',
      }" @select="selectTree"></LeftSelectTree>
    </template>
    <template #right>
      <ComponentTabs :tabMap="tabMap" :key="selected" v-model:activeKey="tabState.activeKey" @change="tabState.changeTab">
        <component :is="tabState.currentComponent" :key="selected" :selectedProject="selected" :data="componentData"></component>
      </ComponentTabs>
    </template>
  </TwoColumnsLayout>
</template>

<script setup>
import { apiConfiguration } from "@/api/configuration";
import { useTreeState } from "@/components/business/LeftSelectTree/hooks/useTreeState";
import { useTabState } from "@/components/basic/tabs/hooks/useTabState";
// import Subarea from "./Subarea.vue";
// import SubItem from "./Subitem.vue";
// import ConfigElectricity from "./ConfigElectricity.vue";
import ElectricWrap from "./electricWrap.vue";
import WaterWrap from "./waterWrap.vue";
import ConfigGas from "./ConfigGas.vue";
import ConfigEnergy from "./ConfigEnergy.vue";
import { computed, watch, ref, nextTick } from "vue";
import { apiProject } from "@/api/IoT/project";

const {
  treeData,
  treeLoading,
  selected,
  selectTree,
  firstLeafItem,
  selectedItem,
} = useTreeState({
  apiFunc: apiConfiguration.projectTree,
  initSelected: () => {
    if (!selected.value) {
      selectTree(firstLeafItem.value?.selectId ?? "");
    }
  },
  queryKey: "selectedProject",
  filterArrayDeepValueKey: "selectId",
});
watch(selectedItem, () => {
  nextTick(()=>{
    initProjectDetail();
  })
});
const componentData = ref({});
const energyType = ref([]);
const energySubSystem = ref([]);
const initProjectDetail = async () => {
  try {
    const res = await apiProject.detail(selectedItem.value.projectId);
    energyType.value = res.result.energyType;
    energySubSystem.value = res.result.energySubSystem || [];
    tabState.value = useTabState(tabMap.value);
    if (energySubSystem.value.length > 0) {
      componentData.value = energySubSystem.value
    }
  } catch (error) {
    console.log(error, selectedItem, "详情");
  }
};

const defaultTabMap = {
  // subarea: {
  //   label: "电耗分区",
  //   component: Subarea,
  //   type: "2",
  // },
  // subItem: {
  //   label: "电耗分类",
  //   component: SubItem,
  //   type: "2",
  // },
  // electricity: {
  //   label: "电耗分时与价格",
  //   component: ConfigElectricity,
  //   type: "2",
  // },
  electric:{
    label: "用电配置",
    component: ElectricWrap,
    type: "2",
  },
  water: {
    label: "用水配置",
    component: WaterWrap,
    type: "1",
  },
  gas: {
    label: "用气配置",
    component: ConfigGas,
    type: "3",
  },
};
const tabMap = computed(() => {
  let _obj = {};
  Object.values(defaultTabMap).forEach((item, index) => {
    if (energyType.value.includes(item.type)) {
      _obj[Object.keys(defaultTabMap)[index]] = item;

    }
  });

  if (energySubSystem.value.length > 0) {
    _obj.energy = {
      label: "能源子系统",
      component: ConfigEnergy,
      type: "4",
    }

  }
  console.log(2323, _obj)
  return _obj;
});
const tabState = ref({});
</script>

<style lang="less" scoped>
.energy-analysis-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 20px 20px;

  &-tabs {
    font-size: 16px;
  }

  &-tabItem {
    flex: 1;
  }
}
</style>
