<template>
  <div>
    <a-radio-group class="tab_nav" v-model:value="tabValue" @change="tabChange">
      <a-radio-button value="subItem">分类配置</a-radio-button>
      <a-radio-button value="subarea">分区配置</a-radio-button>
      <a-radio-button value="configWater">价格配置</a-radio-button>
    </a-radio-group>
    <component :is="componentId" :selectedProject="selectedProject"></component>
  </div>
</template>
<script setup>
import { markRaw, nextTick, ref, shallowRef, defineProps } from 'vue'
import SubareaWater from "./SubareaWater.vue";
import SubitemWater from "./SubitemWater.vue";
import ConfigWater from "./ConfigWater.vue";

const tabValue = ref('subItem')

defineProps({
  selectedProject: {
    type: String,
    default: "",
  },
}) 

const tabEnum = {
    'subarea': markRaw(SubareaWater),
    'subItem': markRaw(SubitemWater),
    'configWater': markRaw(ConfigWater),
}

const componentId = shallowRef(tabEnum[tabValue.value])

const tabChange = (e)=>{
    nextTick(()=>{
        componentId.value = tabEnum[e.target.value]
    })
}

</script>
<style lang="less" scoped>
.tab_nav{
    margin: 20px 0
}
</style>
