export function useEditModalHooks() {
  const devicesFindRepeat = (list) => {
    const _list = [];
    let isFind = false;
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      if (item.bizDeviceId && _list.includes(item.bizDeviceId)) {
        isFind = true;
        break;
      } else {
        isFind = false;
        _list.push(item.bizDeviceId);
      }
    }
    return isFind;
  };
  return {
    devicesFindRepeat,
  };
}
