<template>
  <BasicModal
    :title="title"
    width="500px"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="ok"
    @cancel="cancel"
  >
    <BasicForm
      ref="formRef"
      :labelCol="{ span: 5 }"
      :formItemsMap="formItemsMap"
      v-model:formData="formData"
    ></BasicForm>
  </BasicModal>
</template>

<script setup>
import {
  defineProps,
  computed,
  ref,
  defineEmits,
  reactive,
  onMounted,
} from "vue";
import { apiConfigElectricity } from "@/api/configuration";
import { message } from "ant-design-vue";
import dayjs from "dayjs";

const props = defineProps({
  projectId: {
    type: String,
    default: null,
  },
  detail: {
    type: Object,
    default: null,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef = ref(null);

const title = computed(() => `${props.detail ? (props.detail.isCopy?'复制':'修改') : "添加"}时段`);

onMounted(() => {
 initFormData();
});

const formItemsMap = reactive({
  time: {
    label: "时段月份",
    prop: "time",
    type: "datePicker",
    picker: "month",
    format: "YYYY年M月",
    required: true,
    requiredMessage: "请选择",
  },
  tipPrice: {
    label: "尖时段",
    prop: "tipPrice",
    type: "input",
    inputType: "number",
    placeholder: "请输入单价",
    suffix: "元",
    // required: true,
    // requiredMessage: "请输入",
  },
  tipTimes: {
    label: " ",
    prop: "tipTimes",
    type: "timeRangeList",
    colon: false,
    required: false,
    requiredMessage: "请添加尖时段",
  },
  peakPrice: {
    label: "峰时段",
    prop: "peakPrice",
    type: "input",
    placeholder: "请输入单价",
    suffix: "元",
    // required: true,
    // requiredMessage: "请输入",
  },
  peakTimes: {
    label: " ",
    prop: "peakTimes",
    type: "timeRangeList",
    colon: false,
    required: true,
    requiredMessage: "请添加峰时段",
  },
  valleyPrice: {
    label: "谷时段",
    prop: "valleyPrice",
    type: "input",
    placeholder: "请输入单价",
    suffix: "元",
    required: true,
    requiredMessage: "请输入",
  },
  valleyTimes: {
    label: " ",
    prop: "valleyTimes",
    type: "timeRangeList",
    colon: false,
    required: true,
    requiredMessage: "请添加谷时段",
  },
  flatPrice: {
    label: "平时段",
    prop: "flatPrice",
    type: "input",
    placeholder: "请输入单价",
    suffix: "元",
    required: true,
    requiredMessage: "请输入",
  },
  flatTimes: {
    label: " ",
    prop: "flatTimes",
    type: "timeRangeList",
    colon: false,
    required: true,
    requiredMessage: "请添加平时段",
  },
});
const formData = reactive({
  time: "",
  tipPrice: "",
  tipTimes: [
    {
      timeBegin: undefined,
      timeEnd: undefined,
    },
  ],
  peakPrice: "",
  peakTimes: [
    {
      timeBegin: undefined,
      timeEnd: undefined,
    },
  ],
  valleyPrice: "",
  valleyTimes: [
    {
      timeBegin: undefined,
      timeEnd: undefined,
    },
  ],
  flatPrice: "",
  flatTimes: [
    {
      timeBegin: undefined,
      timeEnd: undefined,
    },
  ],
});
const initFormData = async () => {
  if (!props.detail) return;
  console.log(332323,props.detail )
  for (const key in formData) {
    formData[key] =  key=='time'?(dayjs(props.detail[key],'YYYY年M月')):props.detail[key]
  }
};

const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef.value.formRef.validateFields();
    const { detail } = props;
    const params = {
      projectId: props.projectId,
      ...formData,
    };
    detail&&!detail.isCopy&&(params.originalTime = detail.time);
    params.time = (typeof params.time == 'string')?params.time:dayjs(params.time).format('YYYY年M月');
    confirmLoading.value = true;
    await apiConfigElectricity[detail?(detail.isCopy?'add':'edit'):"add"](params);
    message.success(`${detail ? (detail.isCopy?'复制':'修改') : "添加"}成功`);
    emit("update:visible", false);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped></style>
