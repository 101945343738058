<template>
  <div>
    <a-radio-group class="tab_nav" v-model:value="tabValue" @change="tabChange">
      <a-radio-button value="subItem">分类配置</a-radio-button>
      <a-radio-button value="subarea">分区配置</a-radio-button>
      <a-radio-button value="configElectricity">价格配置</a-radio-button>
    </a-radio-group>
    <component :is="componentId" :selectedProject="selectedProject"></component>
  </div>
</template>
<script setup>
import { markRaw, nextTick, ref, shallowRef, defineProps } from 'vue'
import Subarea from "./Subarea.vue";
import SubItem from "./Subitem.vue";
import ConfigElectricity from "./ConfigElectricity.vue";

defineProps({
  selectedProject: {
    type: String,
    default: "",
  },
}) 
const tabValue = ref('subItem')

const tabEnum = {
    'subarea': markRaw(Subarea),
    'subItem': markRaw(SubItem),
    'configElectricity': markRaw(ConfigElectricity),
}

const componentId = shallowRef(tabEnum[tabValue.value])

const tabChange = (e)=>{
    nextTick(()=>{
        componentId.value = tabEnum[e.target.value]
    })
}

</script>
<style lang="less" scoped>
.tab_nav{
    margin: 20px 0
}
</style>
