<template>
  <div class="config-electricity">
    <div>
      <div class="config-electricity-header-title">
        <span class="config-electricity-header-line"></span>
        <span>电价类型</span>
      </div>
      <div class="electricity-type">
        <a-select
          style="width: 300px"
          v-model:value="electricityType"
          @change="handleChangeType"
        >
          <a-select-option
            v-for="item in typeList"
            :key="item.value"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
        <a-button class="typeStyle" type="primary" @click="handleSaveType">保存</a-button>
      </div>
    </div>
    <div class="config-electricity-header">
      <div class="config-electricity-header-title">
        <span class="config-electricity-header-line"></span>
        <span>电价详情</span>
      </div>
      <div class="config-electricity-header-handle" v-if="electricityType == 2">
        <a-button type="primary" @click="showModal(null)">添加</a-button>
      </div>
    </div>
    <div class="electricity-detail" v-if="electricityType == 1">
      <a-input-number onkeyup="value=value.replace(/[^\d\.]/g,'') "  :parser="value =>value.match(/^[0-9]*\.([0-9]{0,4})|^[0-9]*/)[0]"   style="width: 300px" v-model:value="priceValue" :disabled="isEdit" ref="priceRef" suffix="元/kwh"/>
      <form-outlined
        v-if="isEdit"
        style="font-size: 20px;margin-left: 8px;color: #0256FF;"
        @click="handleEditPrice"
      />
      <a-button style="margin-left: 8px;" v-if="!isEdit" type="primary" @click="handleSavePrice"
        >保存</a-button
      >
      <a-button style="margin-left: 8px;" v-if="!isEdit" @click="handleCancelEdit">取消</a-button>
    </div>
    <BasicTable v-if="electricityType == 2" ref="tableRef" v-bind="tableConfig">
    </BasicTable>
  </div>
  <ConfigElectricityEditModal
    v-if="modalVisible"
    v-model:visible="modalVisible"
    :projectId="selectedProject"
    :detail="currentRow"
    @success="editSuccess"
  ></ConfigElectricityEditModal>
</template>

<script setup>
import { defineProps, watch, ref, reactive, onMounted, nextTick } from "vue";
import { Modal, message } from "ant-design-vue";
import { apiConfigElectricity } from "@/api/configuration";
import { BasicTable } from "@/components/basic/table";
import ConfigElectricityEditModal from "./component/ConfigElectricityEditModal";
import { apiDictListByCode } from "@/api/common";
import dayjs from "dayjs";
import { useUserStore } from "@/store/modules/user";
const userStore = useUserStore();
const tableRef = ref();

const props = defineProps({
  selectedProject: {
    type: String,
    default: "",
  },
});
watch(
  () => props.selectedProject,
  () => {
    if (electricityType.value == 2) {
      initTableData();
    }
  }
);

onMounted(() => {
  if (electricityType.value == 2) {
    initTableData();
  }
  initPriceTypeList();
  initPriceInfo();
});

const priceValue = ref("");
const editpriceValue = ref("");
const priceRef = ref(null);
const electricityType = ref("");
const isEdit = ref(true);
const handleEditPrice = () => {
  isEdit.value = false;
  editpriceValue.value = priceValue.value
  nextTick(() => {
    priceRef.value.focus();
  });
};
const handleSavePrice = () => {
  if(priceValue.value&&priceValue.value>100){
    message.warning('输入价格错误，超过正常范围！');
    priceValue.value = '';
    return;
  }
  isEdit.value = true;
  addConstValue();
};
const handleSaveType = async()=>{
  addConstValue()
}
const handleCancelEdit = () => {
  isEdit.value = true;
  priceValue.value = editpriceValue.value;
};
const handleChangeType = (val) => {
  if (val == 2) {
    initTableData();
  }
};

const typeList = ref([]);
const initPriceTypeList = () => {
  apiDictListByCode("ELECTRICITY_PRICE_TYPE").then((res) => {
    typeList.value = res.result || [];
  });
};

const detailInfo = ref({});
const initPriceInfo = async () => {
  const { result } = await apiConfigElectricity.detailInfo(
    props.selectedProject
  );
  if (result) {
    detailInfo.value = result;
    electricityType.value = result.type;
    priceValue.value = result.price;
    if (electricityType.value == 2) {
      initTableData();
    }
  }
};

const addConstValue = async () => {
  
  await apiConfigElectricity.addConstValue({
    id: detailInfo.value.id,
    projectId: props.selectedProject,
    type: electricityType.value,
    price: priceValue.value?priceValue.value*1:0,
    tenantId: userStore.userInfo && userStore.userInfo.tenantId,
  });
  initPriceInfo();
  message.success('保存成功');
};

const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: false,
  isPageBottom: true,
  remote: false,
  loading: false,
  defaultExpandedRowKeys: [],
  isFinish: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 280px)",
  },
  columns: [
    {
      title: "时段名称",
      type: "text",
      key: "time",
      width: 120,
      align: "left",
    },
    {
      title: "时段范围（h）",
      type: "text",
      key: "timeDuring",
      width: 300,
      align: "left",
    },
    {
      title: "电价（元）",
      type: "text",
      key: "price",
      width: 80,
      align: "left",
    },
  ],
  action: {
    title: "操作",
    width: 70,
    fixed: "right",
    align: "left",
    items: [
      {
        type: "icon",
        icon: "CopyOutlined",
        iconTip: "复制",
        visibleFn: (row) => {
          return row.children?.length;
        },
        onClick: (row) => {
          showModal(row, true);
        },
      },
      {
        type: "icon",
        icon: "FormOutlined",
        iconTip: "修改",
        visibleFn: (row) => {
          const currentDate = new Date();
          const rowDate = new Date(dayjs(row.time, "YYYY年M月"));
          return row.children?.length && rowDate > currentDate;
        },
        onClick: (row) => {
          showModal(row);
        },
      },
      {
        type: "icon",
        icon: "DeleteOutlined",
        iconTip: "删除",
        visibleFn: (row) => {
          const currentDate = new Date();
          const rowDate = new Date(dayjs(row.time, "YYYY年M月"));
          return row.children?.length && rowDate > currentDate;
        },
        onClick: (row) => {
          Modal.confirm({
            title: "提示",
            content: "是否删除该条数据？",
            okType: "danger",
            onOk: () => {
              return new Promise((resolve, reject) => {
                apiConfigElectricity
                  .delete(row.projectId, row.time)
                  .then(() => {
                    message.success("删除成功");
                    initTableData();
                    resolve();
                  })
                  .catch(() => {
                    reject();
                  });
              });
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        },
      },
    ],
  },
});
const initTableData = async () => {
  if (!props.selectedProject) {
    return;
  }
  tableConfig.loading = true;
  tableConfig.isFinish = false;
  try {
    const { result } = await apiConfigElectricity.list(props.selectedProject);
    tableConfig.loading = false;
    tableConfig.isFinish = true;
    tableConfig.defaultExpandedRowKeys = [result[0].time];
    formatTableData(result);
  } catch (error) {
    tableConfig.isFinish = true;
    tableConfig.loading = false;
    console.log(error);
  }
};
const formatTableData = (data) => {
  const _list = data.map((item) => {
    const {
      time,
      tipTimeDuring,
      peakTimeDuring,
      valleyTimeDuring,
      flatTimeDuring,
      tipPrice,
      peakPrice,
      valleyPrice,
      flatPrice,
    } = item;
    const _item = {
      ...item,
      id: time,
      timeDuring: "",
      price: "",
      children: [
        {
          time: "尖时段",
          timeDuring: tipTimeDuring,
          price: tipPrice,
        },
        {
          time: "峰时段",
          timeDuring: peakTimeDuring,
          price: peakPrice,
        },
        {
          time: "谷时段",
          timeDuring: valleyTimeDuring,
          price: valleyPrice,
        },
        {
          time: "平时段",
          timeDuring: flatTimeDuring,
          price: flatPrice,
        },
      ],
    };
    return _item;
  });
  tableRef.value.dataSourceRef = _list;
};

const modalVisible = ref(false);
const currentRow = ref(false);
const showModal = (row, copy) => {
  modalVisible.value = true;
  if (copy) {
    currentRow.value = { ...row, isCopy: true };
    return;
  }
  currentRow.value = row || null;
};
const editSuccess = () => {
  initTableData();
};
</script>

<style lang="less" scoped>
.config-electricity {
  height: 100%;
  &-header {
    display: flex;
    justify-content: space-between;
    &-title {
      display: flex;
      align-items: center;
      gap: 10px;
      height: 56px;
      font-size: 16px;
    }
    &-line {
      width: 3px;
      height: 16px;
      background: #0256ff;
    }
    &-handle {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
.electricity-detail {
  display: flex;
  align-items: center;
  & > span {
    cursor: pointer;
  }
  input {
    width: 200px;
    margin-right: 20px;
  }
}
.typeStyle{
  margin-left: 8px;
}
</style>
