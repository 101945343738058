<template>
  <BasicModal
    :title="title"
    width="500px"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="ok"
    @cancel="cancel"
  >
    <a-spin :spinning="loading">
      <BasicForm
        ref="formRef"
        :labelCol="{ span: 5 }"
        :formItemsMap="formItemsMap"
        v-model:formData="formData"
        @change="changeForm"
      ></BasicForm>
    </a-spin>
  </BasicModal>
</template>

<script setup>
import {
  defineProps,
  computed,
  ref,
  defineEmits,
  reactive,
  onMounted,
} from "vue";
import { apiSubitem, apiSubarea } from "@/api/configuration";
import { apiDictListByCode } from "@/api/common";
import { message } from "ant-design-vue";
import { useEditModalHooks } from "./hooks";

const props = defineProps({
  projectId: {
    type: String,
    default: null,
  },
  spaceId: {
    type: Number,
    default: null,
  },
  visible: {
    type: Boolean,
    default: false,
  },
  type:{
    type: String,
    default: '1',
  }
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef = ref(null);

const title = computed(() => `${props.spaceId ? "修改" : "添加"}分类`);

onMounted(() => {
  initFormItems();
  initFormData();
});

const loading = ref(false);
const formItemsMap = reactive({
  name: {
    label: "分类名称",
    prop: "name",
    type: "input",
    required: true,
    requiredMessage: "请输入",
  },
  parentId: {
    label: "父级分类",
    prop: "parentId",
    type: "treeSelect",
    disabled: false,
    showSearch: true,
    treeNodeFilterProp: "name",
    options: [],
    fieldNames: {
      label: "name",
      value: "id",
    },
  },
  kpiSubtype: {
    label: "KPI类别",
    prop: "kpiSubtype",
    type: "select",
    options: [],
    required: true,
    requiredMessage: "请选择",
  },
  kpiDesc: {
    label: " ",
    type: "text",
    text: "",
    textStyle: { color: "#999999" },
    colon: false,
    hide: true,
  },
  devices: {
    label: "计量设备",
    prop: "devices",
    type: "calculateDevice",
    options: [],
    required: true,
    requiredMessage: "请添加计量设备",
  },
});
const initFormItems = async () => {
  if (props.spaceId) {
    formItemsMap.parentId.disabled = true;
  }
  apiSubitem.list(props.projectId, props.type).then((res) => {
    formItemsMap.parentId.options = res.result;
  });
  apiDictListByCode("CNF_KPI_SUBITEM_TYPE").then((res) => {
    formItemsMap.kpiSubtype.options = res.result;
  });
  apiSubarea.allDeviceList(props.projectId).then((res) => {
    formItemsMap.devices.options = res.result;
  });
};
const formData = reactive({
  name: "",
  parentId: undefined,
  kpiSubtype: undefined,
  devices: [
    {
      bizDeviceId: undefined,
      computeTag: undefined,
    },
  ],
});
const initFormData = async () => {
  if (!props.spaceId) return;
  loading.value = true;
  try {
    const { result } = await apiSubitem.detail(props.spaceId);
    for (const key in formData) {
      formData[key] = result[key];
    }
    getKpiDesc();
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
};
const changeForm = ({ prop }) => {
  if (prop === "kpiSubtype") {
    getKpiDesc();
  }
};
const getKpiDesc = async () => {
  if (!formData.kpiSubtype) {
    formItemsMap.kpiDesc.text = "";
    formItemsMap.kpiDesc.hide = true;
    return;
  }
  try {
    const { result } = await apiSubitem.kpiDesc(formData.kpiSubtype);
    formItemsMap.kpiDesc.text = result;
    formItemsMap.kpiDesc.hide = false;
  } catch (error) {
    console.log(error);
  }
};

const useEditModal = useEditModalHooks();
const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef.value.formRef.validateFields();
    if (useEditModal.devicesFindRepeat(formData.devices)) {
      message.warning("计量设备不能重复");
      return;
    }
    const { spaceId } = props;
    const params = {
      projectId: props.projectId,
      ...formData,
      kpiTypeCode: props.type
    };
    spaceId && (params.id = spaceId);
    confirmLoading.value = true;
    await apiSubitem[spaceId ? "edit" : "add"](params);
    message.success(`${spaceId ? "修改" : "添加"}成功`);
    emit("update:visible", false);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped></style>
