<template>
  <div class="configEnergy">
    <a-radio-group v-model:value="currentTab" button-style="solid" @change="handleTabChange">
      <a-radio-button v-for="item in tabDatas" :key="item.code" :value="item.code">{{ item.name }}</a-radio-button>
    </a-radio-group>
    <div class="energyContent">
      <template v-if="currentTab === 'PHOTOVOLTAIC'">
        <section>
          <label>上网模式：</label>
          <a-select v-model:value="paramObj.onlineMode">
            <a-select-option v-for="item in onlineList" :key="item.id" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </section>
        <section>
          <label>上网电价：</label>
          <a-input type="number" v-model:value="paramObj.price">
            <template #suffix> 元 </template>
          </a-input>
        </section>
        <section>
          <label>装机容量：</label>
          <a-input type="number" v-model:value="paramObj.capacity">
            <template #suffix> kwp </template>
          </a-input>
        </section>
      </template>
      <template v-if="currentTab === 'ENERGY_STORAGE'">
        <section>
          <label>额定功率：</label>
          <a-input type="number" v-model:value="paramObj.ratedPower">
            <template #suffix> kw </template>
          </a-input>
        </section>
        <section>
          <label>最大容量：</label>
          <a-input type="number" v-model:value="paramObj.storageTotal">
            <template #suffix> kwh </template>
          </a-input>
        </section>
      </template>
      <template v-if="currentTab === 'CHARGING_STATION'">
        <section>
          <label>计费模式：</label>
          <a-select v-model:value="paramObj.billingMode">
            <a-select-option v-for="item in chargingList" :key="item.id" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </section>
        <section>
          <label>电费：</label>
          <a-input type="number" v-model:value="paramObj.electricityPrice">
            <template #suffix> 元/kwh </template>
          </a-input>
        </section>
        <section>
          <label>服务费：</label>
          <a-input type="number" v-model:value="paramObj.price">
            <template #suffix> 元/kwh </template>
          </a-input>
        </section>
        <section>
          <label>额定总功率：</label>
          <a-input type="number" v-model:value="paramObj.ratedPower">
            <template #suffix> kw </template>
          </a-input>
        </section>
        <section>
          <label>交流桩数：</label>
          <a-input type="number" v-model:value="paramObj.acStationNum">
          </a-input>
        </section>
        <section>
          <label>直流桩数：</label>
          <a-input type="number" v-model:value="paramObj.dcStationNum">
          </a-input>
        </section>
      </template>
      <a-button type="primary" @click="handelSave">保存</a-button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref, reactive } from "vue";
// import { apiDeviceList } from "@/api/centerMonitor/deviceReport.js";
import { apiConfigEnergy } from "@/api/configuration.js";
import { apiDictListByCode } from "@/api/common.js";
// import { apiDict } from "@/api/dict.js";
import { message } from "ant-design-vue";
// import { cloneDeep } from "lodash";

const paramObj = reactive({
  onlineMode: '',
  price: '',
  electricityPrice:'',
  capacity: '',
  ratedPower:'',
  storageTotal:'',
  billingMode:'',
  acStationNum:'',
  dcStationNum:'',
})

const props = defineProps({
  data: {
    type: Object,
    default() {
      return {}
    }
  },
  selectedProject: {
    type: String,
    default: "",
  },
})


const onlineList = ref([])
const chargingList = ref([])
const initConfigData = async () => {

  let res1 = await apiDictListByCode('PV_ONLINE_MODE')
  onlineList.value = res1.result || []

  let res2 = await apiDictListByCode('CHARGING_STATION_BILLING_MODE')
  chargingList.value = res2.result || []
}

const defaultTabs = [
  {
    name: '光伏系统',
    code: 'PHOTOVOLTAIC',
    key: 'pv'
  },
  {
    name: '储能系统',
    code: 'ENERGY_STORAGE',
    key: 'storage'
  },
  {
    name: '充电桩',
    code: 'CHARGING_STATION',
    key: 'chargeStation'
  },
  {
    name: '暖通',
    code: 'HVAC'
  },
  {
    name: '热水',
    code: 'WATER_FEE'
  },
]
const currentTab = ref('');
const tabDatas = ref([])
const getTabData = () => {
  const codeArrays = Object.values(props.data)
  tabDatas.value = defaultTabs.filter(item => codeArrays.includes(item.code))
  currentTab.value = tabDatas.value[0] && tabDatas.value[0].code
}

const handelSave = () => {
  if (currentTab.value === 'PHOTOVOLTAIC') {
    apiConfigEnergy.pv({
      projectBizId: props.selectedProject,
      onlineMode: paramObj.onlineMode,
      price: paramObj.price,
      capacity: paramObj.capacity,
    }).then(() => {
      message.success('保存成功')
    })
  }
  if (currentTab.value === 'ENERGY_STORAGE') {
    apiConfigEnergy.storage({
      projectBizId: props.selectedProject,
      ratedPower: paramObj.ratedPower,
      storageTotal: paramObj.storageTotal,
    }).then(() => {
      message.success('保存成功')
    })
  }
  if (currentTab.value === 'CHARGING_STATION') {
    apiConfigEnergy.chargeStation({
      projectBizId: props.selectedProject,
      billingMode: paramObj.billingMode,
      price: paramObj.price,
      ratedPower: paramObj.ratedPower,
      acStationNum: paramObj.acStationNum,
      dcStationNum: paramObj.dcStationNum,
    }).then(() => {
      message.success('保存成功')
    })
  }
}

const allDetailInfo = ref({})
const initData = () => {
  apiConfigEnergy.detail(props.selectedProject).then((res) => {
    allDetailInfo.value = res.result
    getCurrentDetail()
  })
}
const getCurrentDetail = () => {
  let findOne = defaultTabs.find(item => item.code === currentTab.value)
  if (findOne) {
    let detail = allDetailInfo.value[findOne.key]
    for(let key in detail){
      if(key !== 'projectBizId'){
        paramObj[key] = detail[key]
      }
    }
  }
}

const handleTabChange = ()=>{
  initData()
}
onMounted(() => {
  getTabData()
  initConfigData();
  initData();
})

</script>

<style lang="less" scoped>
.configEnergy {
  padding-top: 20px;
}

.ant-radio-group {
  margin-bottom: 30px;
}

.energyContent {
  padding-left: 10px;
}

section {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  &>label {
    width: 90px;
  }

  .ant-select,
  .ant-input-affix-wrapper,
  .ant-input {
    width: 300px;
  }
}</style>
